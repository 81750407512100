/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          var isMobile = {
            Android: function() {
              return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function() {
              return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function() {
              return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function() {
              return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function() {
              return navigator.userAgent.match(/IEMobile/i);
            },
            any: function() {
              return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
            }
        };


        var mobileMenuOutsideClick = function() {

            $(document).click(function (e) {
            var container = $("#offcanvas, .js-nav-toggle");
            if (!container.is(e.target) && container.has(e.target).length === 0) {

                if ( $('body').hasClass('offcanvas') ) {

                    $('body').removeClass('offcanvas');
                    $('.js-nav-toggle').removeClass('active');
                }
            }
            });

        };

        var lightbox = function() {
          $('.open-popup-link').magnificPopup({
            type:'inline',
            midClick: true
          });
          $('.woocommerce-product-gallery__image').magnificPopup({
              delegate: 'a',
              type: 'image',
              gallery: {
                  enabled: true,
                  navigateByImgClick: true,
                  preload: [0,1] // Will preload 0 - before current, and 1 after the current image
              }
          });

        };

        var offcanvasMenu = function() {

            $('#page').prepend('<div id="offcanvas" />');
            $('#page').prepend('<a href="#" class="js-nav-toggle nav-toggle nav-white"><i></i></a>');
            var clone1 = $('.menu-1 > ul').clone();
            $('#offcanvas').append(clone1);
            var clone2 = $('.menu-2 .widget_shopping_cart').clone();
            $('#offcanvas').append(clone2);

            $('#offcanvas .has-dropdown').addClass('offcanvas-has-dropdown');
            $('#offcanvas')
                .find('li')
                .removeClass('has-dropdown');

            // Hover dropdown menu on mobile
            $('.offcanvas-has-dropdown').mouseenter(function(){
                var $this = $(this);

                $this
                    .addClass('active')
                    .find('ul')
                    .slideDown(500, 'easeOutExpo');
            }).mouseleave(function(){

                var $this = $(this);
                $this
                    .removeClass('active')
                    .find('ul')
                    .slideUp(500, 'easeOutExpo');
            });

            $(window).resize(function(){

                if ( $('body').hasClass('offcanvas') ) {

                    $('body').removeClass('offcanvas');
                    $('.js-nav-toggle').removeClass('active');

                }
            });
        };


        var burgerMenu = function() {

            $('body').on('click', '.js-nav-toggle', function(event){
                var $this = $(this);

                if ( $('body').hasClass('overflow offcanvas') ) {
                    $('body').removeClass('overflow offcanvas');
                } else {
                    $('body').addClass('overflow offcanvas');
                }
                $this.toggleClass('active');
                event.preventDefault();

            });
        };



        var contentWayPoint = function() {
            var i = 0;
            $('.animate-box').waypoint( function( direction ) {

                if( direction === 'down' && !$(this.element).hasClass('animated-fast') ) {

                    i++;

                    $(this.element).addClass('item-animate');
                    setTimeout(function(){

                        $('body .animate-box.item-animate').each(function(k){
                            var el = $(this);
                            setTimeout( function () {
                                var effect = el.data('animate-effect');
                                if ( effect === 'fadeIn') {
                                    el.addClass('fadeIn animated-fast');
                                } else if ( effect === 'fadeInLeft') {
                                    el.addClass('fadeInLeft animated-fast');
                                } else if ( effect === 'fadeInRight') {
                                    el.addClass('fadeInRight animated-fast');
                                } else {
                                    el.addClass('fadeInUp animated-fast');
                                }

                                el.removeClass('item-animate');
                            },  k * 200, 'easeInOutExpo' );
                        });

                    }, 100);

                }

            } , { offset: '85%' } );
        };


        var dropdown = function() {

            $('.has-dropdown').mouseenter(function(){

                var $this = $(this);
                $this
                    .find('.dropdown')
                    .css('display', 'block')
                    .addClass('animated-fast fadeInUpMenu');

            }).mouseleave(function(){
                var $this = $(this);

                $this
                    .find('.dropdown')
                    .css('display', 'none')
                    .removeClass('animated-fast fadeInUpMenu');
            });

        };


        var goToTop = function() {

            $('.js-gotop').on('click', function(event){

                event.preventDefault();

                $('html, body').animate({
                    scrollTop: $('html').offset().top
                }, 500, 'easeInOutExpo');

                return false;
            });

            $(window).scroll(function(){

                var $win = $(window);
                if ($win.scrollTop() > 200) {
                    $('.js-top').addClass('active');
                } else {
                    $('.js-top').removeClass('active');
                }

            });

        };

        var fullHeight = function() {

            if ( !isMobile.any() ) {
                $('.js-fullheight').css('height', $(window).height());
                $(window).resize(function(){
                    $('.js-fullheight').css('height', $(window).height());
                });
            }

        };

        // Loading page
        var loaderPage = function() {
            $(".loader").fadeOut("slow");
        };

        var sameHeight = function() {
          $('.intro').matchHeight();
          $('.reseller').matchHeight();
        };

        var parallax = function() {
          if ( !isMobile.any() ) {
              $(window).stellar({
                horizontalScrolling: false,
//                  verticalOffset: 150,
                responsive: true
              });
          }
        };

        var initCarousel = function() {

            $('#selection-carousel').owlCarousel({
              loop: true,
//                  margin: 20,
              responsive:{
                  0:{
                      items:1
                  },
                  540:{
                      items:2
                  },
                  900:{
                      items:4
                  }
              }
          });

          $('#product-carousel').owlCarousel({
              loop: true,
              margin: 20,
              responsive:{
                  0:{
                      items:1
                  },
                  540:{
                      items:2
                  },
                  900:{
                      items:4
                  }
              }
          });

        };

        mobileMenuOutsideClick();
        fullHeight();
        offcanvasMenu();
        burgerMenu();
        contentWayPoint();
        dropdown();
        parallax();
        goToTop();
        initCarousel();
        sameHeight();
        lightbox();
        loaderPage();

      },
      finalize: function() {
      }
    },
    // Template Reseller
    'page_template_template_resellers': {
      init: function() {

        // init gmap on reseller page template
        initMap($);
      }
    },
    // Contact page
    'contact': {
      init: function() {

        // init gmap on contact page
        initMap($);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
